import React from "react";

export const Events = (props) => {
  return (
    <div id="events">
      <div className="container">
        <div className="section-title text-center">
          <h2>Events</h2>
          <p>Our event listings are constantly updated, so be sure to check back regularly to stay informed about the latest happenings on our platform. Register to event of your choice to get an amazing experience!</p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.slug}-${i}`} className="col-md-4" style={{marginBottom: '20px'}}>
                <div class="card">
                  <img className="card-img" src={d.img} alt="Avatar" style={{ width: '100%' }} />
                  <div className="card-body" style={{ padding: '8px' }}>
                    <div>
                      <h4><b>{d.title}</b></h4>
                      <p>{d.description}</p>
                      <button type="button" class="btn btn-primary"  data-toggle="modal" data-target={`#modal-${d.slug}`}>
                        Read More
                      </button>
                    </div>
                    {/* <!-- The Modal --> */}
                    <div className="modal" id={`modal-${d.slug}`} tabIndex="-1" aria-labelledby={`modal-${d.slug}-label`} aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">

                          {/* <!-- Modal Header --> */}
                          <div class="modal-header">
                            <h4 class="modal-title">{d.title}</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                          </div>

                          {/* <!-- Modal body --> */}
                          <div className="modal-body">
                            <div className="panel panel-default">
                              <div className="panel-heading panel-heading-nav">
                                <ul className="nav nav-tabs">
                                  <li role="presentation" className="active">
                                    <a href={`#one-${d.slug}`} aria-controls={`one-${d.slug}`} role="tab" data-toggle="tab">About</a>
                                  </li>
                                  <li role="presentation">
                                    <a href={`#two-${d.slug}`} aria-controls={`two-${d.slug}`}role="tab" data-toggle="tab">Details</a>
                                  </li>
                                  <li role="presentation">
                                    <a href={`#three-${d.slug}`} aria-controls={`three-${d.slug}`} role="tab" data-toggle="tab">Pricing</a>
                                  </li>
                                </ul>
                              </div>
                              <div className="panel-body">
                                <div className="tab-content">
                                  <div role="tabpanel" className="tab-pane fade in active" id={`one-${d.slug}`}>
                                    <div>
                                      <h4><b>{d.title}</b></h4>
                                      <p>{d.description}</p>
                                    </div>
                                  </div>
                                  <div role="tabpanel" className="tab-pane fade" id={`two-${d.slug}`}>
                                    <div><p>{d.details}</p></div>
                                  </div>
                                  <div role="tabpanel" className="tab-pane fade" id={`three-${d.slug}`}>
                                    <div><p>{d.pricing}</p></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <!-- Modal footer --> */}
                          <div className="modal-footer">
                            {d.registration === "ongoing" ? (
                              <div>
                              <a href={d.individual_event_url} target="_blank" rel="noreferrer" className="btn btn-warning">Register</a>
                              {d.school_is_included ? (
                              <a href={d.school_event_url} target="_blank" rel="noreferrer" className="btn btn-primary">Register a School</a>

                              ): ""}
                              </div>
                            ) :
                              <button className="btn btn-danger">Ended</button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
